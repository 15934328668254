<template>
  <div class="error">
    <div>
      <div class="error-code">{{ code }}</div>
      <div class="mt-30px mb-30px" style="color: #606266; font-size: 16px">
        {{ title }}
      </div>
      <el-button type="primary" size="small" @click="$router.go(-1)">
        <!-- {{ second }} 秒后返回上一页 -->返回上一页
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      second: 5,
      // timer: null,
    };
  },
  props: {
    code: Number,
    title: String,
  },
  mounted() {
    // this.timer = setInterval(() => {
    //   if (this.second === 0) {
    //     clearInterval(this.timer);
    //     this.$router.go(-1);
    //   } else {
    //     this.second--;
    //   }
    // }, 1000);
  },
  destroyed() {
    // clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.error {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .error-code {
    color: #666;
    font-size: 150px;
  }
}
</style>
